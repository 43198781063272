import abalone from './critterIcons/abalone.png'
import acornbarnacle from './critterIcons/acornbarnacle.png'
import agriasbutterfly from './critterIcons/agriasbutterfly.png'
import anchovy from './critterIcons/anchovy.png'
import angelfish from './critterIcons/angelfish.png'
import ant from './critterIcons/ant.png'
import arapaima from './critterIcons/arapaima.png'
import arowana from './critterIcons/arowana.png'
import atlasmoth from './critterIcons/atlasmoth.png'
import bagworm from './critterIcons/bagworm.png'
import bandeddragonfly from './critterIcons/bandeddragonfly.png'
import barredknifejaw from './critterIcons/barredknifejaw.png'
import barreleye from './critterIcons/barreleye.png'
import bellcricket from './critterIcons/bellcricket.png'
import betta from './critterIcons/betta.png'
import bitterling from './critterIcons/bitterling.png'
import blackbass from './critterIcons/blackbass.png'
import blowfish from './critterIcons/blowfish.png'
import bluegill from './critterIcons/bluegill.png'
import bluemarlin from './critterIcons/bluemarlin.png'
import blueweevilbeetle from './critterIcons/blueweevilbeetle.png'
import browncicada from './critterIcons/browncicada.png'
import butterflyfish from './critterIcons/butterflyfish.png'
import carp from './critterIcons/carp.png'
import catfish from './critterIcons/catfish.png'
import centipede from './critterIcons/centipede.png'
import chamberednautilus from './critterIcons/chamberednautilus.png'
import char from './critterIcons/char.png'
import cherrysalmon from './critterIcons/cherrysalmon.png'
import cicadashell from './critterIcons/cicadashell.png'
import citruslonghornedbeetle from './critterIcons/citruslonghornedbeetle.png'
import clownfish from './critterIcons/clownfish.png'
import coelacanth from './critterIcons/coelacanth.png'
import commonbluebottle from './critterIcons/commonbluebottle.png'
import commonbutterfly from './critterIcons/commonbutterfly.png'
import crawfish from './critterIcons/crawfish.png'
import cricket from './critterIcons/cricket.png'
import cruciancarp from './critterIcons/cruciancarp.png'
import cyclommatusstag from './critterIcons/cyclommatusstag.png'
import dab from './critterIcons/dab.png'
import dace from './critterIcons/dace.png'
import damselfly from './critterIcons/damselfly.png'
import darnerdragonfly from './critterIcons/darnerdragonfly.png'
import divingbeetle from './critterIcons/divingbeetle.png'
import dorado from './critterIcons/dorado.png'
import dronebeetle from './critterIcons/dronebeetle.png'
import dungbeetle from './critterIcons/dungbeetle.png'
import dungenesscrab from './critterIcons/dungenesscrab.png'
import earthboringdungbeetle from './critterIcons/earthboringdungbeetle.png'
import emperorbutterfly from './critterIcons/emperorbutterfly.png'
import eveningcicada from './critterIcons/eveningcicada.png'
import firefly from './critterIcons/firefly.png'
import fireflysquid from './critterIcons/fireflysquid.png'
import flatworm from './critterIcons/flatworm.png'
import flea from './critterIcons/flea.png'
import fly from './critterIcons/fly.png'
import footballfish from './critterIcons/footballfish.png'
import freshwatergoby from './critterIcons/freshwatergoby.png'
import frog from './critterIcons/frog.png'
import gar from './critterIcons/gar.png'
import gazamicrab from './critterIcons/gazamicrab.png'
import giantcicada from './critterIcons/giantcicada.png'
import giantisopod from './critterIcons/giantisopod.png'
import giantsnakehead from './critterIcons/giantsnakehead.png'
import giantstag from './critterIcons/giantstag.png'
import gianttrevally from './critterIcons/gianttrevally.png'
import giantwaterbug from './critterIcons/giantwaterbug.png'
import gigasgiantclam from './critterIcons/gigasgiantclam.png'
import giraffestag from './critterIcons/giraffestag.png'
import goldenstag from './critterIcons/goldenstag.png'
import goldentrout from './critterIcons/goldentrout.png'
import goldfish from './critterIcons/goldfish.png'
import goliathbeetle from './critterIcons/goliathbeetle.png'
import grasshopper from './critterIcons/grasshopper.png'
import greatpurpleemperor from './critterIcons/greatpurpleemperor.png'
import greatwhiteshark from './critterIcons/greatwhiteshark.png'
import guppy from './critterIcons/guppy.png'
import hammerheadshark from './critterIcons/hammerheadshark.png'
import hermitcrab from './critterIcons/hermitcrab.png'
import honeybee from './critterIcons/honeybee.png'
import hornedatlas from './critterIcons/hornedatlas.png'
import horneddynastid from './critterIcons/horneddynastid.png'
import hornedelephant from './critterIcons/hornedelephant.png'
import hornedhercules from './critterIcons/hornedhercules.png'
import horsemackerel from './critterIcons/horsemackerel.png'
import horseshoecrab from './critterIcons/horseshoecrab.png'
import jewelbeetle from './critterIcons/jewelbeetle.png'
import killifish from './critterIcons/killifish.png'
import kingsalmon from './critterIcons/kingsalmon.png'
import koi from './critterIcons/koi.png'
import ladybug from './critterIcons/ladybug.png'
import loach from './critterIcons/loach.png'
import lobster from './critterIcons/lobster.png'
import longlocust from './critterIcons/longlocust.png'
import madagascansunsetmoth from './critterIcons/madagascansunsetmoth.png'
import mahimahi from './critterIcons/mahimahi.png'
import manfacedstinkbug from './critterIcons/manfacedstinkbug.png'
import mantis from './critterIcons/mantis.png'
import mantisshrimp from './critterIcons/mantisshrimp.png'
import migratorylocust from './critterIcons/migratorylocust.png'
import mittencrab from './critterIcons/mittencrab.png'
import miyamastag from './critterIcons/miyamastag.png'
import molecricket from './critterIcons/molecricket.png'
import monarchbutterfly from './critterIcons/monarchbutterfly.png'
import moonjellyfish from './critterIcons/moonjellyfish.png'
import morayeel from './critterIcons/morayeel.png'
import mosquito from './critterIcons/mosquito.png'
import moth from './critterIcons/moth.png'
import mussel from './critterIcons/mussel.png'
import napoleonfish from './critterIcons/napoleonfish.png'
import neontetra from './critterIcons/neontetra.png'
import nibblefish from './critterIcons/nibblefish.png'
import oarfish from './critterIcons/oarfish.png'
import oceansunfish from './critterIcons/oceansunfish.png'
import octopus from './critterIcons/octopus.png'
import oliveflounder from './critterIcons/oliveflounder.png'
import orchidmantis from './critterIcons/orchidmantis.png'
import oyster from './critterIcons/oyster.png'
import palechub from './critterIcons/palechub.png'
import paperkitebutterfly from './critterIcons/paperkitebutterfly.png'
import peacockbutterfly from './critterIcons/peacockbutterfly.png'
import pearloyster from './critterIcons/pearloyster.png'
import pike from './critterIcons/pike.png'
import pillbug from './critterIcons/pillbug.png'
import piranha from './critterIcons/piranha.png'
import pondskater from './critterIcons/pondskater.png'
import pondsmelt from './critterIcons/pondsmelt.png'
import popeyedgoldfish from './critterIcons/popeyedgoldfish.png'
import pufferfish from './critterIcons/pufferfish.png'
import queenalexandrasbirdwing from './critterIcons/queenalexandrasbirdwing.png'
import rainbowfish from './critterIcons/rainbowfish.png'
import rainbowstag from './critterIcons/rainbowstag.png'
import rajahbrookesbirdwing from './critterIcons/rajahbrookesbirdwing.png'
import ranchugoldfish from './critterIcons/ranchugoldfish.png'
import ray from './critterIcons/ray.png'
import reddragonfly from './critterIcons/reddragonfly.png'
import redkingcrab from './critterIcons/redkingcrab.png'
import redsnapper from './critterIcons/redsnapper.png'
import ribboneel from './critterIcons/ribboneel.png'
import ricegrasshopper from './critterIcons/ricegrasshopper.png'
import robustcicada from './critterIcons/robustcicada.png'
import rosaliabatesibeetle from './critterIcons/rosaliabatesibeetle.png'
import saddledbichir from './critterIcons/saddledbichir.png'
import salmon from './critterIcons/salmon.png'
import sawshark from './critterIcons/sawshark.png'
import sawstag from './critterIcons/sawstag.png'
import scallop from './critterIcons/scallop.png'
import scarabbeetle from './critterIcons/scarabbeetle.png'
import scorpion from './critterIcons/scorpion.png'
import seaanemone from './critterIcons/seaanemone.png'
import seabass from './critterIcons/seabass.png'
import seabutterfly from './critterIcons/seabutterfly.png'
import seacucumber from './critterIcons/seacucumber.png'
import seagrapes from './critterIcons/seagrapes.png'
import seahorse from './critterIcons/seahorse.png'
import seapig from './critterIcons/seapig.png'
import seapineapple from './critterIcons/seapineapple.png'
import seaslug from './critterIcons/seaslug.png'
import seastar from './critterIcons/seastar.png'
import seaurchin from './critterIcons/seaurchin.png'
import seaweed from './critterIcons/seaweed.png'
import slatepencilurchin from './critterIcons/slatepencilurchin.png'
import snail from './critterIcons/snail.png'
import snappingturtle from './critterIcons/snappingturtle.png'
import snowcrab from './critterIcons/snowcrab.png'
import softshelledturtle from './critterIcons/softshelledturtle.png'
import spider from './critterIcons/spider.png'
import spidercrab from './critterIcons/spidercrab.png'
import spinylobster from './critterIcons/spinylobster.png'
import spottedgardeneel from './critterIcons/spottedgardeneel.png'
import squid from './critterIcons/squid.png'
import stinkbug from './critterIcons/stinkbug.png'
import stringfish from './critterIcons/stringfish.png'
import sturgeon from './critterIcons/sturgeon.png'
import suckerfish from './critterIcons/suckerfish.png'
import surgeonfish from './critterIcons/surgeonfish.png'
import sweetfish from './critterIcons/sweetfish.png'
import sweetshrimp from './critterIcons/sweetshrimp.png'
import tadpole from './critterIcons/tadpole.png'
import tarantula from './critterIcons/tarantula.png'
import tigerbeetle from './critterIcons/tigerbeetle.png'
import tigerbutterfly from './critterIcons/tigerbutterfly.png'
import tigerprawn from './critterIcons/tigerprawn.png'
import tilapia from './critterIcons/tilapia.png'
import tuna from './critterIcons/tuna.png'
import turbanshell from './critterIcons/turbanshell.png'
import umbrellaoctopus from './critterIcons/umbrellaoctopus.png'
import unknown from './critterIcons/unknown.png'
import vampiresquid from './critterIcons/vampiresquid.png'
import venusflowerbasket from './critterIcons/venusflowerbasket.png'
import violinbeetle from './critterIcons/violinbeetle.png'
import walkercicada from './critterIcons/walkercicada.png'
import walkingleaf from './critterIcons/walkingleaf.png'
import walkingstick from './critterIcons/walkingstick.png'
import wasp from './critterIcons/wasp.png'
import whaleshark from './critterIcons/whaleshark.png'
import wharfroach from './critterIcons/wharfroach.png'
import whelk from './critterIcons/whelk.png'
import yellowbutterfly from './critterIcons/yellowbutterfly.png'
import yellowperch from './critterIcons/yellowperch.png'
import zebraturkeyfish from './critterIcons/zebraturkeyfish.png'

const icons = {
    abalone: abalone,
    acornbarnacle: acornbarnacle,
    agriasbutterfly: agriasbutterfly,
    anchovy: anchovy,
    angelfish: angelfish,
    ant: ant,
    arapaima: arapaima,
    arowana: arowana,
    atlasmoth: atlasmoth,
    bagworm: bagworm,
    bandeddragonfly: bandeddragonfly,
    barredknifejaw: barredknifejaw,
    barreleye: barreleye,
    bellcricket: bellcricket,
    betta: betta,
    bitterling: bitterling,
    blackbass: blackbass,
    blowfish: blowfish,
    bluegill: bluegill,
    bluemarlin: bluemarlin,
    blueweevilbeetle: blueweevilbeetle,
    browncicada: browncicada,
    butterflyfish: butterflyfish,
    carp: carp,
    catfish: catfish,
    centipede: centipede,
    chamberednautilus: chamberednautilus,
    char: char,
    cherrysalmon: cherrysalmon,
    cicadashell: cicadashell,
    citruslonghornedbeetle: citruslonghornedbeetle,
    clownfish: clownfish,
    coelacanth: coelacanth,
    commonbluebottle: commonbluebottle,
    commonbutterfly: commonbutterfly,
    crawfish: crawfish,
    cricket: cricket,
    cruciancarp: cruciancarp,
    cyclommatusstag: cyclommatusstag,
    dab: dab,
    dace: dace,
    damselfly: damselfly,
    darnerdragonfly: darnerdragonfly,
    divingbeetle: divingbeetle,
    dorado: dorado,
    dronebeetle: dronebeetle,
    dungbeetle: dungbeetle,
    dungenesscrab: dungenesscrab,
    earthboringdungbeetle: earthboringdungbeetle,
    emperorbutterfly: emperorbutterfly,
    eveningcicada: eveningcicada,
    firefly: firefly,
    fireflysquid: fireflysquid,
    flatworm: flatworm,
    flea: flea,
    fly: fly,
    footballfish: footballfish,
    freshwatergoby: freshwatergoby,
    frog: frog,
    gar: gar,
    gazamicrab: gazamicrab,
    giantcicada: giantcicada,
    giantisopod: giantisopod,
    giantsnakehead: giantsnakehead,
    giantstag: giantstag,
    gianttrevally: gianttrevally,
    giantwaterbug: giantwaterbug,
    gigasgiantclam: gigasgiantclam,
    giraffestag: giraffestag,
    goldenstag: goldenstag,
    goldentrout: goldentrout,
    goldfish: goldfish,
    goliathbeetle: goliathbeetle,
    grasshopper: grasshopper,
    greatpurpleemperor: greatpurpleemperor,
    greatwhiteshark: greatwhiteshark,
    guppy: guppy,
    hammerheadshark: hammerheadshark,
    hermitcrab: hermitcrab,
    honeybee: honeybee,
    hornedatlas: hornedatlas,
    horneddynastid: horneddynastid,
    hornedelephant: hornedelephant,
    hornedhercules: hornedhercules,
    horsemackerel: horsemackerel,
    horseshoecrab: horseshoecrab,
    jewelbeetle: jewelbeetle,
    killifish: killifish,
    kingsalmon: kingsalmon,
    koi: koi,
    ladybug: ladybug,
    loach: loach,
    lobster: lobster,
    longlocust: longlocust,
    madagascansunsetmoth: madagascansunsetmoth,
    mahimahi: mahimahi,
    manfacedstinkbug: manfacedstinkbug,
    mantis: mantis,
    mantisshrimp: mantisshrimp,
    migratorylocust: migratorylocust,
    mittencrab: mittencrab,
    miyamastag: miyamastag,
    molecricket: molecricket,
    monarchbutterfly: monarchbutterfly,
    moonjellyfish: moonjellyfish,
    morayeel: morayeel,
    mosquito: mosquito,
    moth: moth,
    mussel: mussel,
    napoleonfish: napoleonfish,
    neontetra: neontetra,
    nibblefish: nibblefish,
    oarfish: oarfish,
    oceansunfish: oceansunfish,
    octopus: octopus,
    oliveflounder: oliveflounder,
    orchidmantis: orchidmantis,
    oyster: oyster,
    palechub: palechub,
    paperkitebutterfly: paperkitebutterfly,
    peacockbutterfly: peacockbutterfly,
    pearloyster: pearloyster,
    pike: pike,
    pillbug: pillbug,
    piranha: piranha,
    pondskater: pondskater,
    pondsmelt: pondsmelt,
    popeyedgoldfish: popeyedgoldfish,
    pufferfish: pufferfish,
    queenalexandrasbirdwing: queenalexandrasbirdwing,
    rainbowfish: rainbowfish,
    rainbowstag: rainbowstag,
    rajahbrookesbirdwing: rajahbrookesbirdwing,
    ranchugoldfish: ranchugoldfish,
    ray: ray,
    reddragonfly: reddragonfly,
    redkingcrab: redkingcrab,
    redsnapper: redsnapper,
    ribboneel: ribboneel,
    ricegrasshopper: ricegrasshopper,
    robustcicada: robustcicada,
    rosaliabatesibeetle: rosaliabatesibeetle,
    saddledbichir: saddledbichir,
    salmon: salmon,
    sawshark: sawshark,
    sawstag: sawstag,
    scallop: scallop,
    scarabbeetle: scarabbeetle,
    scorpion: scorpion,
    seaanemone: seaanemone,
    seabass: seabass,
    seabutterfly: seabutterfly,
    seacucumber: seacucumber,
    seagrapes: seagrapes,
    seahorse: seahorse,
    seapig: seapig,
    seapineapple: seapineapple,
    seaslug: seaslug,
    seastar: seastar,
    seaurchin: seaurchin,
    seaweed: seaweed,
    slatepencilurchin: slatepencilurchin,
    snail: snail,
    snappingturtle: snappingturtle,
    snowcrab: snowcrab,
    softshelledturtle: softshelledturtle,
    spider: spider,
    spidercrab: spidercrab,
    spinylobster: spinylobster,
    spottedgardeneel: spottedgardeneel,
    squid: squid,
    stinkbug: stinkbug,
    stringfish: stringfish,
    sturgeon: sturgeon,
    suckerfish: suckerfish,
    surgeonfish: surgeonfish,
    sweetfish: sweetfish,
    sweetshrimp: sweetshrimp,
    tadpole: tadpole,
    tarantula: tarantula,
    tigerbeetle: tigerbeetle,
    tigerbutterfly: tigerbutterfly,
    tigerprawn: tigerprawn,
    tilapia: tilapia,
    tuna: tuna,
    turbanshell: turbanshell,
    umbrellaoctopus: umbrellaoctopus,
    unknown: unknown,
    vampiresquid: vampiresquid,
    venusflowerbasket: venusflowerbasket,
    violinbeetle: violinbeetle,
    walkercicada: walkercicada,
    walkingleaf: walkingleaf,
    walkingstick: walkingstick,
    wasp: wasp,
    whaleshark: whaleshark,
    wharfroach: wharfroach,
    whelk: whelk,
    yellowbutterfly: yellowbutterfly,
    yellowperch: yellowperch,
    zebraturkeyfish: zebraturkeyfish,
}

export default function getIconForCritterName(name) {
    const icon = icons[name.toLowerCase().replace(/[^a-z]/gi, '')];
    return icon ? icon : unknown;
}
